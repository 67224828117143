import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import EnumRole from "../common/EnumRole";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

const EditUser = (prop, ref) => {
  const _ref = useRef<any>();

  const _formItems: Array<IFormItemProps> = [{
    formItemProps: {
      name: "userId",
      hidden: true
    }
  },
  {
    formItemProps: {
      label: "姓名",
      name: "userRealName",
    },
  },
  {
    formItemProps: {
      label: "用户名",
      name: "userName",
      extra:<>初始密码为【cczj0000】</>
    },
  },
  {
    type: "select",
    formItemProps: {
      label: "用户类型",
      name: "userRole",
    },
    formControlProps: {
      options: EnumRole.filter(item => item.code != "superadmin"),
      fieldNames: {
        label: "label",
        value: "value",
      },
    },
  },
  ];

  const saveData = async (data) => {
    const _res = await useDispatchAsync({
      type: data.userId ? "user/UpdateUser" : "user/AddUser",
      data: data
    });

    if (_res.code === ResponseCode.Success) {
      message.success("用户信息保存成功");
      prop?.onSave?.()
      return;
    }

    return _res.message;
  }

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <ModalForm
      ref={_ref}
      title="添加/编辑用户"
      formItems={_formItems}
      onOk={saveData}
    ></ModalForm>
  );
};

export default React.forwardRef(EditUser);
