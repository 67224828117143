const routes = [
  {
    path: "/user",
    menuName: "用户管理",
    icon: "UserOutlined",
    code: [1],
    auth: (data) => {
      return data?.userRole != 3
    },
    sort: 8,
    children: [
      {
        path: "list",
        menuName: "用户列表",
        element: () => import("./userlist"),
      },
      // {
      //   path: "detail",
      //   menuName: "用户详情",
      //   element: () => import("./userdetail"),
      // },
    ],
  },
];

export default routes;
