import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import { Button, Space, Popconfirm, message, Tooltip } from "antd";
import { RenderIcon } from "@package/icons";
import Edit from "./edit";
import EnumRole from "@src/javascripts/common/enums/EnumRole";
import dayjs from "dayjs";
import { IFormItemProps } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { useSelector } from "react-redux";

const UserList = () => {
  const [table] = RemoteTable.useTable();
  const userInfo = useSelector((state: any) => state.global?.userInfo || {});
  const _ref = useRef<any>();

  const _columns = [
    // {
    //   title: "ID",
    //   dataIndex: "userId",
    // },
    {
      title: "姓名",
      dataIndex: "userRealName",
      filter: true,
    },
    {
      title: "用户名",
      dataIndex: "userName",
    },
    {
      title: "用户类型",
      dataIndex: "userRole",
      render: (data) => {
        return EnumRole.find((item) => item.value === data)?.label ?? data;
      },
    },
    {
      title: "最后修改时间",
      dataIndex: "updateTime",
      render: (data) => {
        return data ? dayjs(data).format("YYYY年MM月DD日") : "----";
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        if (record.userId === 1) return <></>;
        return (
          <Space>
            <Button
              type="link"
              onClick={() => editUser(record)}
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
            >
              编辑
            </Button>
            <>{
              userInfo.userId != record.userId && <>
                <Popconfirm
                  title={`确定重置用户【${record.userName}】的密码？`}
                  onConfirm={() => resetPassword(record)}
                >
                  <Button
                    type="link"
                    icon={<RenderIcon type="KeyOutlined"></RenderIcon>}
                    danger
                  >
                    <Tooltip title={<>重置后密码为初始密码【cczj0000】</>}>
                      密码重置
                    </Tooltip>
                  </Button>
                </Popconfirm>
                {record.isDeleted ? (
                  <Popconfirm
                    title={`确定禁用用户【${record.userName}】`}
                    onConfirm={() => enableUser(record)}
                  >
                    <Button
                      danger
                      type="link"
                      icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                    >
                      启用
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title={`确定禁用用户【${record.userName}】`}
                    onConfirm={() =>
                      useDelTableRecord("user/DeleteUser")(
                        {
                          id: record.userId,
                        },
                        (res) => {
                          table.reload();
                          message.success(`用户【${record.userName}】已被禁用`);
                        }
                      )
                    }
                  >
                    <Button
                      danger
                      type="link"
                      icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                    >
                      禁用
                    </Button>
                  </Popconfirm>
                )}
              </>
            }</>
          </Space>
        );
      },
    },
  ];

  const editUser = (data?: any) => {
    _ref.current.setVisible(true);

    if (data?.userId) {
      _ref.current.setFieldsValue(data);
    }
  };

  const resetPassword = async (data?: any) => {
    const _res = await useDispatchAsync({
      type: "user/ResetUserPwd",
      data: {
        userId: data.userId,
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("密码重置成功！");
      return;
    }

    message.error(_res.msg);
  };

  const enableUser = async (data?: any) => {
    const _res = await useDispatchAsync({
      type: "user/EnableUser",
      data: {
        userId: data.userId,
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success(`用户【${data.userName}】重新启用成功`);
      table.reload();
      return;
    }

    message.error(_res.message);
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        rowKey={"userId"}
        scroll={{ x: true }}
        loadData={useLoadTableData("user/UserList", (searchParams) => {
          const { showAll, ...restparams } = searchParams;
          return {
            ...restparams,
            showAll,
          };
        })}
        extraFilterItems={
          [
            {
              type: "switch",
              formItemProps: {
                name: "showAll",
                label: "显示全部",
              },
              formControlProps: {
                checkedChildren: "是",
                unCheckedChildren: "否",
              },
            },
          ] as Array<IFormItemProps>
        }
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => editUser()}
          >
            添加用户
          </Button>
        }
      ></RemoteTable>
      <Edit ref={_ref} onSave={() => table.reload()}></Edit>
    </>
  );
};

export default UserList;
