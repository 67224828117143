import React, { useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";

const EditUser = () => {
  const _ref = useRef<any>();

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "ID",
        hidden: true,
      },
    },
    {
      formItemProps: {
        label: "角色名称",
      },
    },
    {
      formItemProps: {
        label: "角色权限",
      },
    },
  ];
  return (
    <ModalForm
      ref={_ref}
      title="添加/编辑角色"
      formItems={_formItems}
    ></ModalForm>
  );
};

export default React.forwardRef(EditUser);
