import React, { useEffect } from "react";
import Routers, { Models } from "@js/pages";
import { useSelector } from "react-redux";
import token from "@js/common/storage/token";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useLocalDispatch } from "@js/common/useDispatch";

const BootstrapApp = ({ children }) => {
  const params = useParams();

  const token_state = useSelector(
    (state: any) => state.loginInfo?.token || null
  );

  const getUserSetting = () => {
    window.$store.dispatchAsync({
      type: "global/UserProfile",
    });
    // window.$store.dispatchAsync({
    //   type: "global/MenuList",
    // });
    // window.$store.dispatchAsync({
    //   type: "global/AuthMenuList",
    // });
  };

  useEffect(() => {
    if (Array.isArray(Models)) {
      Models.forEach((model) => {
        const { effects, namespace, reducers } = model;

        if (effects)
          window.$store.sagaInstance.register({ effects, namespace });
        if (reducers) window.$store.reducerInstance.register(reducers);
      });
    }

    if (params.token) token.save(params.token);

    console.log(params.token,token.get(),'---token')
    if (!!token.get() || params.token) {
      getUserSetting();
    }
  }, []);

  useEffect(() => {
    if (!!token_state) {
      // getUserSetting();
    }
  }, [token_state]);

  return <>{children}</>;
};

export default BootstrapApp;
