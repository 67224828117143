import { IPageModel } from "@package/store";

export default {
  namespace: "user",
  actionType: {
    Post: {
      UserList: "/backapi/users/list",
      AddUser: "/backapi/users/add",
      UpdateUser: "/backapi/users/update",
      DeleteUser: "/backapi/users/delete",
      ResetUserPwd: "/backapi/users/resetpwd",
      UserPwdUpdate: "/backapi/users/modifyPwd",
      EnableUser: "/backapi/users/enable",
    },
    Get: {},
  },
} as IPageModel;
